// Standard library imports
import React, { useContext, useEffect, useRef, useState } from 'react';
// External library imports
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

// Internal module imports
import { NordpoolService } from '../../../services/NordpoolService';
import { LoaderContext } from '../../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../../constants';
import ChartWithDateSelector from '../../../components/ApexCharts/Chart';
import { enumerateDaysBetweenDates, datetimeOnlyForGraph } from '../../../utils/dateHelper';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import DownloadFilterModal from './DownloadFilterModal';
import { HttpClient } from '../../../utils/httpClient';
import Button from '../../../components/Buttons/Button';
import DownloadIcon from '../../../assets/download.svg';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import { DropdownComponent } from '../../../components/Inputs/Input';

// Css module imports
import classes from '../Nordpool.module.css';

const ChartContainer = ({ refresh, PriceDropdown, updateQueryParams, queryParamsData }) => {
    const [graphData, setGraphData] = useState([]);
    const [date, setDate] = useState(new Date());
    const [lastStatus, setLastStatus] = useState('--');
    const [openDownloadModal, setOpenDownlodFilterModal] = useState(false);
    const [noOfDays, setNoOfDays] = useState(queryParamsData.noOfDays ? queryParamsData.noOfDays : { label: '1', value: 1 });
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const [dateRange, setDateRange] = useState({
        startDate: queryParamsData.dateRange ? new Date(queryParamsData.dateRange.startDate) : new Date(),
        endDate: queryParamsData.dateRange ? new Date(queryParamsData.dateRange.endDate) : new Date(),
    });
    const [excelData, setExcelData] = useState([]);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const savingsRef = useRef(graphData);

    useEffect(() => updateQueryParams('noOfDays', JSON.stringify(noOfDays)), [noOfDays]);
    useEffect(() => updateQueryParams('dateRange', JSON.stringify(dateRange)), [dateRange]);

    useEffect(() => {
        if (queryParamsData.noOfDays && queryParamsData.noOfDays.value > 1) {
            fetchDataWithFormattedDates();
        } else {
            handFetchDSignalData(dateRange?.startDate);
        }
        let signal = {
            signal: 'DK1,DK2,DK-GAS',
        };
        NordpoolService.lastReported(
            signal,
            () => startLoader('getData'),
            handleLastReportedSuccess,
            handleError,
            () => stopLoader('getData')
        );
    }, []);
    const handFetchDSignalData = (queryDate) => {
        let processedDate = {
            startDate: moment(dateRange.startDate).tz('Europe/Berlin').format('YYYY-MM-DD'),
            endDate: moment(dateRange.endDate).tz('Europe/Berlin').format('YYYY-MM-DD'),
        };
        NordpoolService.getData(
            moment(queryDate).tz('Europe/Berlin').format('YYYY-MM-DD'),
            () => startLoader('getData'),
            handleGetDataSuccess,
            handleError,
            () => stopLoader('getData')
        );
    };
    const handleGetDataSuccess = ({ data }) => {
        setDataToGraphData(data);
    };
    const handleLastReportedSuccess = ({ data }) => {
        const ndate = moment(data.data.price.start_time).format('YYYY-MM-DD');
        setLastStatus(ndate);
    };
    const handleError = (err) => console.log(err);
    const handleDownloadExcel = () => {
        excelData.length > 0 &&
            DownloadAsExcel(
                excelData,
                `Economics ${moment(dateRange.startDate).format('YYYY-MM-DD')}-${moment(dateRange.endDate).format('YYYY-MM-DD')}`,
                Object.keys(excelData[0]).map((item) => String(item).toUpperCase())
            );
    };
    const minStartDate = new Date();
    minStartDate.setDate(dateRange.endDate.getDate() - 2);

    const maxEndDate = new Date();
    maxEndDate.setDate(dateRange.startDate.getDate() + 2);

    const setDataToGraphData = (data) => {
        if (savingsRef.current?.length > 0) {
            let processedData = [];
            savingsRef.current.forEach((signalGraph) => {
                console.log(signalGraph, 'danger');
                const filterData = data.data.graph.find((el) => el.name == signalGraph.name);
                let newData = {
                    ...signalGraph,
                    data: [...signalGraph?.data, ...filterData.data],
                };
                processedData.push(newData);
            });
            savingsRef.current = processedData;
        } else {
            savingsRef.current = [...savingsRef.current, ...data.data.graph];
        }
        setGraphData(savingsRef.current);
    };
    const fetchDataWithFormattedDates = () => {
        let endDate = new Date(dateRange.startDate);
        endDate.setDate(endDate.getDate() + noOfDays.value);
        const rangeArray = enumerateDaysBetweenDates(dateRange.startDate, endDate);
        console.log(rangeArray);
        console.log('coderun');
        if (rangeArray.length >= 4 || rangeArray.length === 0) {
            toast.error('Diffrence between start Date and End Date should not greater than 3 days');
            return;
        }
        startLoader('getDataGraph');
        savingsRef.current = [];

        let nordpoolEndPoint = '/client/data/power-cost';

        rangeArray.length >= 1 &&
            HttpClient.get(nordpoolEndPoint, {
                params: {
                    date: moment(rangeArray[0]).tz('Europe/Berlin').format('YYYY-MM-DD'),
                },
            }).then(({ data }) => {
                rangeArray.length === 1 && stopLoader('getDataGraph');
                setDataToGraphData(data);
                rangeArray.length >= 2 &&
                    HttpClient.get(nordpoolEndPoint, {
                        params: {
                            date: moment(rangeArray[1]).tz('Europe/Berlin').format('YYYY-MM-DD'),
                        },
                    }).then(({ data }) => {
                        rangeArray.length === 2 && stopLoader('getDataGraph');
                        setDataToGraphData(data);
                        rangeArray.length >= 3 &&
                            HttpClient.get(nordpoolEndPoint, {
                                params: {
                                    date: moment(rangeArray[2]).tz('Europe/Berlin').format('YYYY-MM-DD'),
                                },
                            }).then(({ data }) => {
                                setDataToGraphData(data);
                                stopLoader('getDataGraph');
                            });
                    });
            });

        console.log('codeStop');
    };

    return (
        <div className={classes.ChartWrapper}>
            <DownloadFilterModal PriceDropdown={PriceDropdown} open={openDownloadModal} setOpen={setOpenDownlodFilterModal} />
            <div className={`${classes.DatePickers} DateLabels`}>
                <div>
                    <div className={classes.DateLabels}>Start Date</div>
                    <DatePicker date={dateRange.startDate} setDate={(date) => setDateRange({ ...dateRange, startDate: new Date(date) })} size={'large'} />
                </div>
                <div>
                    <div className={`${classes.DateLabels}`}>No Of Days</div>
                    <DropdownComponent
                        defaultValue={noOfDays}
                        options={Array.from({ length: 3 }).map((e, i) => ({ label: `${i + 1}`, value: i + 1 }))}
                        onChange={(e) => {
                            setNoOfDays(e);
                        }}
                    />
                </div>
                <div
                    className={classes.SubmitButton}
                    onClick={() => {
                        fetchDataWithFormattedDates();
                    }}
                >
                    <button className="btn-primary" style={{ height: '2.6vw' }}>
                        Submit
                    </button>
                </div>
                <div>
                    <DownloadButton style={{ height: '1.6vw' }} onClick={() => setOpenDownlodFilterModal(true)} />
                </div>
            </div>
            <div className={classes.BottomContainer}>
                <ChartWithDateSelector
                    legend={true}
                    series={graphData}
                    title="Nordpool (DKK/mW)"
                    xFormatter={datetimeOnlyForGraph}
                    type="bar"
                    date={date}
                    setDate={setDate}
                    lastReported={lastStatus}
                    lastReportedStyle={{ position: 'unset' }}
                    addYAxisDynamicPadding={true}
                    tooltipProps={{ intersect: false }}
                />
            </div>
        </div>
    );
};

export default ChartContainer;
