import React, { useEffect, useState } from 'react';
import momentTz from 'moment-timezone';
import ChartComponent from '../../../components/ApexCharts/Chart';
import { timeOnly } from '../../../utils/dateHelper';
import classes from '../Home.module.css';
import { useLoader } from '../../../hooks/use-loader.hook';
import { toDkFormatter } from '../../../utils/currencyHelper';
import { NordpoolService } from '../../../services/NordpoolService';

const NordPoolCard = ({ groupId }) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        NordpoolService.getData(momentTz().tz('Europe/Berlin').format('YYYY-MM-DD'), startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => setData(data.data.graph);

    const handleError = (error) => {
        console.error(error);
    };

    return (
        <div className={classes.ChartContainer} style={{ height: '28vw' }}>
            <ChartComponent
                title="Power Cost(DKK/MWh)"
                strokeWidth={2}
                type="bar"
                series={data}
                xFormatter={timeOnly}
                yFormatter={toDkFormatter}
                linkTitle={{
                    name: 'Power Cost(DKK/MWh)',
                    link: '/demandResponse/dayahead',
                }}
                xType={'category'}
                xProps={{ tickAmount: 5 }}
                xLabelProps={{ rotate: 0 }}
                addYAxisDynamicPadding={true}
                tooltipProps={{ intersect: false }}
            />
        </div>
    );
};

export default NordPoolCard;
